import { Container, Grid } from "@material-ui/core";
import { BeeBoxFooter } from "@space-apps/honeycomb";
import React from "react";
import Header from "./Header";

const Page: React.FC<{ children: any }> = ({ children }) => {
  return (
    <Grid container direction="column" justify="space-between" style={{ minHeight: "100%" }}>
      <Header />
      <Grid item style={{ flex: "1 0 auto" }}>
        <Container>{children}</Container>
      </Grid>
      <BeeBoxFooter email="office@space-apps.net" phone="+36 20 294 7278" />
    </Grid>
  );
};

export default Page;
