import axios, { AxiosRequestConfig } from "axios";
import React, { useCallback, useEffect, useReducer, useState } from "react";
import { ReducerActionWithoutPayload, ReducerActionWithPayload } from "../types";
import useDeepMemo from "./useDeepMemo";

export interface RequestResult<D, E = any> {
  loading: boolean;
  data?: D;
  error?: E;
}

export interface RequestConfig extends AxiosRequestConfig {
  skip?: boolean;
}

export type Fetch = () => void;

enum ActionType {
  SetPending,
  SetSuccess,
  SetFailure,
}

export default function useRequest<D, P = undefined, E = any>(
  url: string,
  params?: P,
  config?: RequestConfig,
): [RequestResult<D, E>, Fetch] {
  type SetPending = ReducerActionWithoutPayload<ActionType.SetPending>;
  type SetSuccess = ReducerActionWithPayload<ActionType.SetSuccess, D>;
  type SetFailure = ReducerActionWithPayload<ActionType.SetFailure, E>;
  type Action = SetPending | SetSuccess | SetFailure;

  const savedParams = useDeepMemo(params);

  const skip = config?.skip ?? false;
  const [count, setCount] = useState(skip ? 0 : 1);

  const reducer = useCallback<React.Reducer<RequestResult<D, E>, Action>>((state, action) => {
    switch (action.type) {
      case ActionType.SetPending:
        return {
          loading: true,
        };

      case ActionType.SetSuccess:
        return {
          loading: false,
          data: action.payload,
        };

      case ActionType.SetFailure:
        return {
          loading: false,
          error: action.payload,
        };

      default:
        throw new Error("Unknown action type");
    }
  }, []);

  const [state, dispatch] = useReducer(reducer, { loading: !skip });

  useEffect(() => {
    async function sendRequest(): Promise<void> {
      try {
        dispatch({ type: ActionType.SetPending });
        const { data } = await axios.get<D>(url, { params: savedParams, ...config });
        dispatch({ type: ActionType.SetSuccess, payload: data });
      } catch (error) {
        dispatch({ type: ActionType.SetFailure, payload: error as E });
      }
    }
    void sendRequest();
  }, [count, url, savedParams, config]);

  // TODO: handle config change in callback
  return [state, (): void => setCount((c) => c + 1)];
}
