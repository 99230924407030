import { useMemo } from "react";
import { Measurement } from "../types";
import { DataInterval, formatDateTime, formatNumber, groupMeasurementsByInterval } from "../util";

export default function useAggregatedValues(
  msSeries: Measurement[],
  interval: DataInterval,
): Array<Measurement<number>> {
  return useMemo(() => {
    const groupedDict = groupMeasurementsByInterval(msSeries, interval.deltaTimeInHours);
    return Object.entries(groupedDict).reduce<Array<Measurement<number>>>((acc, [time, ms]) => {
      const numArray = ms as Array<Measurement<number>>;
      const sum = numArray.reduce((s, [, value]) => s + value, 0);
      const avg = sum / ms.length;
      acc.push([formatDateTime(time), formatNumber(avg)]);
      return acc;
    }, []);
  }, [msSeries, interval]);
}
