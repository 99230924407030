import { CircularProgress } from "@material-ui/core";
import React from "react";
import { useRememberMe } from "../hooks";

const RememberMe: React.FC = ({ children }) => {
  const [loading] = useRememberMe();
  return loading ? <CircularProgress /> : <>{children}</>;
};

export default RememberMe;
