import { Button } from "@material-ui/core";
import { ButtonProps } from "@material-ui/core/Button";
import React, { forwardRef } from "react";
import Link from "./Link";

interface Props extends ButtonProps {
  to: string;
}

const ButtonLink: React.FC<Props> = forwardRef(({ to, children, ...rest }, ref) => {
  return (
    /* eslint-disable-next-line */
    // @ts-ignore
    <Button ref={ref} component={Link} to={to} {...rest}>
      {children}
    </Button>
  );
});

export default ButtonLink;
