import AppBar from "@material-ui/core/AppBar";
import Grid from "@material-ui/core/Grid";
import Toolbar from "@material-ui/core/Toolbar";
import React from "react";
import BackButton from "./BackButton";
import LogoutButton from "./LogoutButton";

const Header: React.FC = () => {
  return (
    <AppBar position="static">
      <Grid component={Toolbar} justify="space-between">
        <BackButton />
        <LogoutButton />
      </Grid>
    </AppBar>
  );
};

export default Header;
