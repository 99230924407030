import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import axios from "axios";
import endOfToday from "date-fns/endOfToday";
import sub from "date-fns/sub";
import isNil from "lodash/isNil";
import React, { useEffect, useState } from "react";
import { HiveDevice, PartialHive, SensorResult } from "../types";
import { formatDateTime, formatNumber } from "../util";
import ButtonLink from "./ButtonLink";

const HiveCard: React.FC<{ hive: PartialHive; device?: HiveDevice }> = ({ hive, device }) => {
  const { id, name, description } = hive;

  const [weight, setWeight] = useState<number>();

  useEffect(() => {
    async function query(): Promise<void> {
      if (device) {
        const to = endOfToday();
        const from = sub(to, { days: 3 });
        try {
          const recentWeight = await axios.get<SensorResult<number>>(
            `/device/${device.id}/sensor/weight/measurements/series/`,
            {
              params: { from, to },
              paramsSerializer: ({ from: f, to: t }) =>
                `date_from=${formatDateTime(f)}&date_to=${formatDateTime(t)}`,
            },
          );
          setWeight(recentWeight.data.data[recentWeight.data.data.length - 1][1]);
        } catch (err) {
          // Do nothing
        }
      }
    }
    void query();
  }, [device]);

  return (
    <Card variant="outlined">
      <CardContent>
        <Typography color="textSecondary" gutterBottom>
          {id}
        </Typography>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5" component="h2">
              {name}
            </Typography>
            <Typography variant="body2" component="p">
              {description}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h5" component="p">
              {!isNil(weight) ? formatNumber(weight) : 0}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
      <CardActions>
        <ButtonLink to={`/hives/${id}`} size="small">
          Tovább
        </ButtonLink>
      </CardActions>
    </Card>
  );
};

export default HiveCard;
