import addHours from "date-fns/addHours";
import compareAsc from "date-fns/compareAsc";
import format from "date-fns/format";
import isWithinInterval from "date-fns/isWithinInterval";
import { Dictionary, isNil } from "lodash";
import isBoolean from "lodash/isBoolean";
import isNumber from "lodash/isNumber";
import isString from "lodash/isString";
import round from "lodash/round";
import { Measurement, MeasurementValue, Point } from "./types";

export const DATE_FORMAT = "y-MM-dd";
export const TIME_FORMAT = "HH:mm";
export const DATE_TIME_FORMAT = `${DATE_FORMAT} ${TIME_FORMAT}`;

export type MeasurementsDict = Dictionary<Measurement[]>;

export function compareDates(a: Date | string, b: Date | string): number {
  return compareAsc(new Date(a), new Date(b));
}

interface SensorNameI18n {
  readonly [key: string]: string | undefined;
  readonly Battery: string;
  readonly Humidity: string;
  readonly Temp_load: string;
  readonly Weight: string;
  readonly Wifi: string;
}

export interface DataInterval {
  key: string;
  label: string;
  days: number;
  month: number;
  deltaTimeInHours: number;
}

export const INTERVALS: DataInterval[] = [
  {
    key: "1",
    label: "Elmúlt 1 hét",
    days: 7,
    month: 0,
    deltaTimeInHours: 1,
  },
  {
    key: "2",
    label: "Elmúlt 1 hónap",
    days: 0,
    month: 1,
    deltaTimeInHours: 6,
  },
  {
    key: "3",
    label: "Elmúlt 3 hónap",
    days: 0,
    month: 3,
    deltaTimeInHours: 24,
  },
];

const SENSOR_NAME_I18N: SensorNameI18n = {
  Battery: "Akkumulátor",
  Humidity: "Páratartalom",
  Temp_load: "Külső hőmérséklet",
  Weight: "Súly",
  Wifi: "WiFi",
};

export function formatSensorName(name: keyof SensorNameI18n | string): string {
  return SENSOR_NAME_I18N[name] ?? "Unknown";
}

export function sortMeasurementsByDate<V extends MeasurementValue>(
  measurements: Array<Measurement<V>>,
  direction = 1,
): Array<Measurement<V>> {
  return measurements.sort(([a], [b]) => direction * compareDates(a, b));
}

export function formatDate(date: Date | string): string {
  return format(new Date(date), DATE_FORMAT);
}

export function formatDateTime(date: Date | string): string {
  return format(new Date(date), DATE_TIME_FORMAT);
}

export function formatNumber(number: number): number {
  return round(number, 2);
}

export function isPoint(value: MeasurementValue): value is Point {
  return !isBoolean(value) && !isString(value) && !isNumber(value);
}

export function formatMeasurementValue(value: MeasurementValue): string | number {
  if (isNumber(value)) {
    return formatNumber(value);
  }
  if (isPoint(value)) {
    return `[${value.coordinates[0]}, ${value.coordinates[1]}]`;
  }
  return value.toString();
}

export function groupMeasurementsByInterval(
  measurements: Measurement[],
  intervalInHours: number,
): MeasurementsDict {
  const sorted = sortMeasurementsByDate(measurements);
  if (sorted.length !== 0) {
    let startTime = new Date(sorted[0][0]);
    const groupedByInterval = sorted.reduce<MeasurementsDict>((accumulator, [time, value]) => {
      if (
        isWithinInterval(new Date(time), {
          start: startTime,
          end: addHours(startTime, intervalInHours),
        })
      ) {
        if (isNil(accumulator[startTime.toISOString()])) {
          accumulator[startTime.toISOString()] = [];
        }
        accumulator[startTime.toISOString()].push([time, value]);
      } else {
        startTime = addHours(startTime, intervalInHours);
      }
      return accumulator;
    }, {});

    return groupedByInterval;
  }
  return {};
}
