import React, { useMemo } from "react";
import { useParams } from "react-router-dom";
import Device from "./Device";

const DevicePage: React.FC = () => {
  const params = useParams<{ deviceId: string }>();
  const deviceId = useMemo(() => Number.parseInt(params.deviceId, 10), [params.deviceId]);
  return <Device deviceId={deviceId} />;
};

export default DevicePage;
