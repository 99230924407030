import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import DevicePage from "./DevicePage";
import Hive from "./Hive";
import HiveList from "./HiveList";
import LoginForm from "./LoginForm";
import Page from "./Page";
import ProtectedRoute from "./ProtectedRoute";

const Routes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute path="/login">
        <Grid container justify="center" alignItems="center" style={{ height: "100%" }}>
          <Grid item>
            <Paper style={{ padding: 16 }}>
              <LoginForm />
            </Paper>
          </Grid>
        </Grid>
      </ProtectedRoute>

      <ProtectedRoute guard path="/">
        <Page>
          <Switch>
            <Route exact path={["/", "/hives"]}>
              <HiveList />
            </Route>

            <Route guard exact path="/hives/:hiveId">
              <Hive />
            </Route>
            <Route exact guard path="/hives/:hiveId/devices/:deviceId">
              <DevicePage />
            </Route>
          </Switch>
        </Page>
      </ProtectedRoute>

      <Route path="/404">404</Route>

      <Redirect to="/404" />
    </Switch>
  );
};

export default Routes;
