import { IconButton } from "@material-ui/core";
import { ArrowBack as ArrowBackIcon } from "@material-ui/icons";
import React from "react";
import { useHistory } from "react-router-dom";

const BackButton: React.FC = () => {
  const history = useHistory();
  return (
    <IconButton edge="start" color="inherit" aria-label="back" onClick={() => history.goBack()}>
      <ArrowBackIcon />
    </IconButton>
  );
};

export default BackButton;
