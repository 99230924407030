import React from "react";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { useUser } from "../hooks";

interface Props extends RouteProps {
  guard?: boolean;
}

const ProtectedRoute: React.FC<Props> = ({ guard = false, children, ...rest }) => {
  const [user] = useUser();
  if (guard) {
    return user ? <Route {...rest}>{children}</Route> : <Redirect to="/login" />;
  }
  return user ? <Redirect to="/" /> : <Route {...rest}>{children}</Route>;
};

export default ProtectedRoute;
