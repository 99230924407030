import { isNil } from "lodash";
import { useEffect, useState } from "react";
import { User } from "../types";
import useRequest from "./useRequest";
import useUser from "./useUser";

export default function useRememberMe(): [boolean, User | undefined] {
  const [user, setUser] = useUser();

  const [pending, setPending] = useState(true);
  const [{ loading, data }] = useRequest<User>("/session/");

  useEffect(() => {
    if (!loading) {
      if (!isNil(data)) {
        setUser(data);
      }
      setPending(false);
    }
  }, [loading, data, setUser]);

  return [pending, user];
}
