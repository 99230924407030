import { Grid, MenuItem, TextField, Typography } from "@material-ui/core";
import { ResponsiveLine } from "@nivo/line";
import React, { useMemo } from "react";
import { useAggregatedValues } from "../hooks";
import { Measurement } from "../types/sensor";
import { DataInterval, INTERVALS } from "../util";

export interface WeightGraphProps {
  measurements: Measurement[];
  interval: DataInterval;
  setDataInterval: (interval: DataInterval) => void;
}

const WeightGraph: React.FC<WeightGraphProps> = ({ measurements, interval, setDataInterval }) => {
  const values = useAggregatedValues(measurements, interval);
  const data = useMemo(() => {
    const weightData = values.map(([date, weight]) => ({
      x: date,
      y: weight,
    }));
    return [
      {
        id: "weight",
        data: weightData,
      },
    ];
  }, [values]);

  return (
    <div>
      <Grid container justify="flex-end" alignItems="center" spacing={2}>
        <Grid item>
          <Typography variant="subtitle1">Nézet:</Typography>
        </Grid>
        <Grid>
          <TextField
            id="select-interval"
            select
            value={interval.key}
            onChange={(e) => {
              setDataInterval(
                INTERVALS.find((item) => item.key === e.target.value) ?? INTERVALS[0],
              );
            }}
            variant="outlined"
          >
            {INTERVALS.map((option) => (
              <MenuItem key={option.key} value={option.key}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
      </Grid>
      <div style={{ height: "320px" }}>
        <ResponsiveLine
          data={data}
          margin={{ top: 50, right: 60, bottom: 50, left: 45 }}
          xFormat="time:%Y-%m-%d %H:%M"
          xScale={{ type: "time", format: "%Y-%m-%d %H:%M", useUTC: false }}
          yScale={{ type: "linear", min: "auto", max: "auto", stacked: true, reverse: false }}
          axisTop={null}
          axisRight={null}
          axisBottom={{
            format: "%Y %b %d",
            orient: "bottom",
            tickRotation: 15,
            legend: `${interval.label} időszakban mért súly adatok`,
            legendOffset: 40,
            legendPosition: "middle",
          }}
          colors={{ scheme: "nivo" }}
          pointSize={10}
          pointColor={{ theme: "background" }}
          pointBorderWidth={2}
          pointBorderColor={{ from: "serieColor" }}
          pointLabel="y"
          pointLabelYOffset={-12}
          useMesh
        />
      </div>
    </div>
  );
};

export default WeightGraph;
