import { Card, CardContent, Grid, Typography } from "@material-ui/core";
import React from "react";
import { Sensor } from "../types/sensor";
import { formatDateTime, formatSensorName } from "../util";

const SensorCard: React.FC<{ sensor: Sensor; date: string; value: React.ReactText }> = ({
  sensor,
  date,
  value,
}) => {
  const { name, description } = sensor;
  return (
    <Card>
      <CardContent>
        <Typography color="textSecondary">{formatDateTime(date)}</Typography>
        <Grid container justify="space-between" alignItems="flex-start">
          <Grid item>
            <Typography variant="h5" component="h2">
              {formatSensorName(name)}
            </Typography>
            <Typography variant="body1" component="p">
              {description}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant="h4" component="p">
              {value}
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default SensorCard;
