import { CircularProgress, Grid } from "@material-ui/core";
import React from "react";
import { useHiveList } from "../hooks";
import HiveCard from "./HiveCard";

const HiveList: React.FC = () => {
  const [{ loading, data }] = useHiveList();

  if (loading || !data) return <CircularProgress />;

  return (
    <Grid container direction="column" justify="flex-start" alignItems="stretch" spacing={2}>
      {data.data.map((hive) => (
        <Grid key={hive.id} item>
          <HiveCard key={hive.id} hive={hive} device={hive.devices[0]} />
        </Grid>
      ))}
    </Grid>
  );
};

export default HiveList;
